.App {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.input,
.text,
#hint {
  position: absolute;
  text-align: left;
  z-index: 10;
  font-size: 24px;
  padding: 0 1em 0.2em;
  margin: -0.5em 0 0 -1em;
  line-height: 1.2;
}

.input {
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px dashed #888;
}

.text {
  border: 1px dashed transparent;
}

#hint {
  color: #888;
}
